import { VFC } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { object, string } from "yup";

import { useCreateFeatureRequestMutation } from "src/graphql";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { TextArea } from "src/ui/input";
import { Modal } from "src/ui/modal";

type Props = {
  onClose: () => void;
};

const validationSchema = object().shape({
  description: string().required("A message is required."),
});

export const FeatureRequestForm: VFC<Readonly<Props>> = ({ onClose }) => {
  const { addToast } = useToasts();

  const { register, handleSubmit } = useForm<{
    description: string;
  }>({
    resolver: yupResolver(validationSchema),
  });

  const { isLoading: creating, mutateAsync: createFeatureRequest } = useCreateFeatureRequestMutation();

  const onSubmit = async ({ description }) => {
    await createFeatureRequest({ description });

    onClose();

    addToast("Feature request sent, thank you!", { appearance: "success" });
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={creating} onClick={handleSubmit(onSubmit)}>
            Send
          </Button>
        </>
      }
      sx={{ maxWidth: "568px", width: "100%" }}
      title="Submit a feature request"
      onClose={onClose}
    >
      <Field label="Message">
        <TextArea {...register("description")} placeholder="Describe your situation and the feature request" rows={6} />
      </Field>
    </Modal>
  );
};
